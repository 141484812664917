import { ReactElement, Fragment } from 'react';

import usePdfContext from '../../../../Context/usePdfContext';
import { formatPhoneNumber, formatBankNumber, formatAddress } from './Helpers/variantPdfHelper';
import { renderColumnBlockWithIndent } from './RenderColumn';
import { ColumnContainer, Column, Section, Separator, Subtitle } from './Styles/PdfVariantBStyles';

export function PayorInformation(): ReactElement | null {
  const { payorInformation = [] } = usePdfContext();
  return (
    <Section>
      {payorInformation.map((payor: any, index: number) => (
        <Fragment key={index}>
          <Subtitle>Payeur</Subtitle>
          <Separator />
          <ColumnContainer>
            <Column width={65}>
              <div>
                {'Prénom et nom:'} <strong>{payor.firstName + ' ' + payor.lastName}</strong>
              </div>
              {renderColumnBlockWithIndent(
                'Adresse',
                formatAddress(payor.address, payor.address2, payor.city, payor.provinceOrState, payor.postalCode),
              )}
              {renderColumnBlockWithIndent('Courriel', payor.email)}
              {renderColumnBlockWithIndent('Tél ', formatPhoneNumber(payor.phoneNumber))}
            </Column>
            <Column width={35}>
              <div>{`Info bancaire: ${formatBankNumber(payor.pad)}`}</div>
            </Column>
          </ColumnContainer>
        </Fragment>
      ))}
    </Section>
  );
}
