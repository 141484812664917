import styled, { css } from 'styled-components';

type AvoidInnerPageBreak = { avoidInnerPageBreak?: boolean };
type ColumnProps = { width?: number };

export const DocumentTitle = styled.h1`
  margin-bottom: 0px;
`;

export const Title = styled.h1`
  font-size: 20px;
  font-weight: bold;
`;

export const Subtitle = styled.h2<{ margin?: string }>`
  color: ${(props) => props.theme.colors.text.base};
  font-size: 16px;
  font-weight: bold;
  margin: ${(props) => props.margin ?? '10px 0 5px 0'};
`;

export const Header = styled.header`
  text-align: center;
  margin-bottom: 1em;

  & > div {
    font-size: 11px;
  }
`;

export const Section = styled.div<AvoidInnerPageBreak>`
  page-break-inside: ${(props) => (props.avoidInnerPageBreak ?? false ? 'avoid' : 'auto')};
  margin-bottom: 2em;
`;

export const SectionContainer = styled.div``;

export const SubSectionContainer = styled.div<{ pageBreak: boolean }>`
  ${({ pageBreak }) =>
    pageBreak
      ? css`
          break-before: page;
        `
      : css`
          page-break-inside: avoid;
        `}
`;

export const SectionTable = styled.table`
  text-align: left;
  width: 100%;

  & > tbody {
    line-height: 14px;
  }
`;

export const SectionTableHeader = styled.thead`
  border-bottom: 1px double ${(props) => props.theme.colors.text.light};
`;

export const SectionTableFooter = styled.tfoot`
  & th:first-of-type {
    text-align: right;
  }
`;

export const SectionTableRow = styled.tr``;

export const ContentRow = styled(SectionTableRow)`
  & > td {
    padding-top: 4px;
    font-size: 11px;
  }
`;

export const OptionsTitle = styled.td`
  padding-top: 18px !important;
  font-weight: ${(props) => props.theme.fonts.weight.semibold};
`;

export const TableLine = styled.td`
  border-bottom: 1px solid black;
`;

export const RightAlignContent = styled.td`
  text-align: right;
  padding-right: 0.75em;
  font-size: 11px;
`;

export const P = styled.p`
  margin: 1em 0;
  page-break-inside: avoid;
  font-size: 11px;
`;

export const PdfVariantFieldText = styled.p`
  font-size: 11px;
`;

export const PdfVariantFieldGroupHeader = styled.p`
  font-size: 11px;
  font-weight: 600;
  line-height: 20px;
  overflow-wrap: anywhere;
  margin-bottom: 0;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const Column = styled.div<ColumnProps>`
  padding-right: 20px;
  width: ${(props) => (props.width ? `${props.width}%` : '40%')};
  line-height: 14px;
  & > * {
    font-size: 11px;
  }
`;

export const ColumnTitle = styled.span`
  min-width: 4.2em;
`;

export const IndentedBlock = styled.div`
  margin-left: 1.75em;
`;

export const RowSpacing = styled.div`
  display: flex;
  margin: 10px 0 10px 0;
`;

export const Separator = styled.hr`
  height: 0;
  background-color: ${(props) => props.theme.colors.text.base};
`;
