import { ReactElement, Fragment } from 'react';

import { Language, ProductAddon } from '@breathelife/types';

import usePdfContext from '../../../../Context/usePdfContext';
import { formatCurrency } from './Helpers/variantPdfHelper';
import {
  Section,
  Title,
  SectionTable,
  SectionTableFooter,
  SectionTableHeader,
  SectionTableRow,
  Subtitle,
  RightAlignContent,
  ContentRow,
  OptionsTitle,
  TableLine,
  Separator,
} from './Styles/PdfVariantBStyles';

export function ProductInformation(): ReactElement | null {
  const { productsEntity, currency, addons, application } = usePdfContext();
  const { lang: language, premium = 0 } = application;

  if (typeof productsEntity === 'undefined') {
    return null;
  }

  const product = productsEntity.products[0];

  return (
    <Section>
      <Title>Protection d’assurance</Title>
      <Separator />
      <SectionTable>
        <SectionTableHeader>
          <SectionTableRow>
            <th>
              <Subtitle>Description de la protection d’assurance</Subtitle>
            </th>
            <RightAlignContent>
              <Subtitle>
                <strong>Montant de protection</strong>
              </Subtitle>
            </RightAlignContent>
            <RightAlignContent>
              <Subtitle>
                <strong>Prime mensuelle</strong>
              </Subtitle>
            </RightAlignContent>
          </SectionTableRow>
        </SectionTableHeader>

        <tbody>
          <ContentRow>
            <td>{product.name[Language.fr]}</td>
            <RightAlignContent>
              {formatCurrency(product.coverageAmount, currency, language, { minimumFractionDigits: 0 })}
            </RightAlignContent>
            <RightAlignContent>{formatCurrency(product.premium, currency, language)}</RightAlignContent>
          </ContentRow>
          {typeof addons !== 'undefined' &&
            addons.length !== 0 &&
            addons.map((addon: ProductAddon, index: number) => {
              if (addon.premium === 0) return null;
              return (
                <Fragment key={index}>
                  <ContentRow>
                    <td>{addon.description}</td>
                    <RightAlignContent>
                      {formatCurrency(product.coverageAmount, currency, language, { minimumFractionDigits: 0 })}
                    </RightAlignContent>
                    <RightAlignContent>{formatCurrency(addon.premium, currency, language)}</RightAlignContent>
                  </ContentRow>
                </Fragment>
              );
            })}

          <ContentRow>
            <OptionsTitle>Options incluses sans frais</OptionsTitle>
          </ContentRow>

          {typeof addons !== 'undefined' &&
            addons.length !== 0 &&
            addons.map((addon: ProductAddon, index: number) => {
              if (addon.premium !== 0) return null;
              return (
                <Fragment key={index}>
                  <ContentRow>
                    <td>{addon.description}</td>
                    <RightAlignContent />
                    <RightAlignContent>{formatCurrency(addon.premium, currency, language)}</RightAlignContent>
                  </ContentRow>
                </Fragment>
              );
            })}
          <ContentRow>
            <td>Paiement anticipé en cas de maladie en phase terminale</td>
            <RightAlignContent />
            <RightAlignContent>{formatCurrency(0.0, currency, language)}</RightAlignContent>
          </ContentRow>
          <ContentRow />
          <ContentRow>
            <td />
            <td />
            <TableLine />
          </ContentRow>
        </tbody>

        {typeof premium !== 'undefined' && premium >= 0 && (
          <SectionTableFooter>
            <ContentRow>
              <th />
              <RightAlignContent>
                <strong>{'TOTAL'.toUpperCase()}</strong>
              </RightAlignContent>
              <RightAlignContent>{formatCurrency(premium, currency, language)}</RightAlignContent>
            </ContentRow>
          </SectionTableFooter>
        )}
      </SectionTable>
    </Section>
  );
}
