import { IsoCountryCode, Language, LanguageRegion } from '@breathelife/types';

const LanguageRegions: { [key in Language]: LanguageRegion } = {
  [Language.fr]: LanguageRegion.frCa,
  [Language.en]: LanguageRegion.enCa,
};

export function getLanguageRegion(language: Language): LanguageRegion {
  return LanguageRegions[language];
}

type CurrencyOptions = Pick<Intl.NumberFormatOptions, 'minimumFractionDigits'>;

export function toCurrency(
  amount: number,
  currency: string,
  language: Language,
  options: CurrencyOptions = {},
): string {
  const locale = LanguageRegions[language];

  return new Intl.NumberFormat(locale, { currency, style: 'currency', minimumFractionDigits: 2, ...options }).format(
    amount,
  );
}
const currencyMap: { [country in IsoCountryCode]?: string } = {
  US: 'USD',
  CA: 'CAD',
};

export function getCurrency(countryCode: IsoCountryCode): string {
  // TODO: have a currency mapping for every country instead of relying on a fallback
  return currencyMap[countryCode] || 'CAD';
}
