import _ from 'lodash';
import styled, { css } from 'styled-components';

import { Grid, GridProps } from '@breathelife/mui';

export type GridPropsWithText = GridProps & { text: string; subtitle?: string; fieldIndex?: string };

export const GridContainer = styled(Grid)`
  padding-left: 50px;
`;

export const GridItem = styled(Grid)<{ padding?: string }>`
  padding: ${(props) => props.padding ?? 0};
  display: flex;
`;

export const SignaturesContainer = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
`;

export const SequenceNumber = styled.span<{ minWidth?: string }>`
  float: left;
  margin-right: 5px;
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  color: #333333;
  ${(props) => props.minWidth && { 'min-width': props.minWidth }};
`;

export const VariantText = styled.p<{ align?: string; weight?: number; grey?: number | string }>`
  color: ${(props) => props.theme.colors?.grey?.[90]};
  letter-spacing: 0.2px;
  font-size: 11px;
  line-height: 14px;
  text-align: ${(props) => props.align ?? 'left'};
  font-weight: ${(props) => props.weight ?? 400};
  margin-bottom: 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  ol {
    white-space: pre-line;
  }
  ${(props) =>
    !_.isUndefined(props.grey) &&
    css`
      color: ${props.grey === 'inherit' ? 'inherit' : props.theme.colors?.grey?.[props.grey]};
    `}
`;
