import { ParticipantRoles } from '@breathelife/types';

export default {
  yes: 'Yes',
  no: 'No',
  pdfTitle: {
    forInsuredName: 'For {{applicantNames}}',
    lineOfBusiness: {
      annuity: {
        title: 'Application for Membership and Investment',
      },
      life: {
        title: 'Personalized Insurance Application',
      },
    },
  },
  advisorInformation: {
    header: 'Agent',
    name: 'Agent Name',
    number: 'Agent Number',
    email: 'Email',
    phoneNumber: 'Phone Number',
  },
  productInformation: {
    coverageInformation: 'Coverage Information',
    product: {
      one: 'Product',
      other: 'Products',
    },
    productDescription: 'Product Name',
    coverage: 'Coverage',
    monthlyPremium: 'Monthly Premium',
    annualPremium: 'Annual',
    semiAnnualPremium: 'Semi-Annual Premium',
    quarterlyPremium: 'Quarterly Premium',
    addonOptionPrefix: 'Addon: ',
    addonDescription: 'Addon Name',
    includedAddon: 'Included',
    totalMonthlyPremium: 'Total Monthly Premium',
    totalAnnualPremium: 'Total Annual Premium',
    totalSemiAnnualPremium: 'Total Semi-annual Premium',
    totalQuarterlyPremium: 'Total Quarterly Premium',
    riderDescription: 'Rider Name',
    equivalentSingleAge: {
      title: 'Equivalent Single Age (ESA)',
      value: '{{age}} year old',
    },
  },
  proposedInsuredInformation: {
    header: 'Proposed Insured Information',
    proposedInsured: 'Proposed Insured',
    fullName: 'Full Name',
    title: 'Title',
    firstName: 'First Name',
    middleName: 'Middle Name',
    lastName: 'Last Name',
    suffix: 'Suffix',
    gender: 'Gender',
    male: 'Male',
    female: 'Female',
    dateOfBirth: 'Date of Birth',
    smokerStatus: 'Smoker Status',
    smoker: 'Smoker',
    nonSmoker: 'Non-smoker',
    address: 'Address',
    phoneNumber: 'Phone Number',
    email: 'Email',
    previousFirstName: 'Previous First Name',
    previousLastName: 'Previous Last Name',
    occupation: 'Occupation',
    canadianCitizenship: 'Canadian Citizenship',
    countryOfBirth: 'Country of Birth',
    provinceOfBirth: 'Province of Birth',
    address2: 'Address 2',
    city: 'City',
    provinceOrState: 'Province', // FIXME: Determine province or state
    postalCode: 'Postal Code',
    yes: 'Yes',
    no: 'No',
    timeOfTheDay: 'Time of Day',
  },
  annuitantInformation: {
    header: 'Annuitant Information',
    fullName: 'Full Name',
    title: 'Title',
    firstName: 'First Name',
    middleName: 'Middle Name',
    lastName: 'Last Name',
    dateOfBirth: 'Date of Birth',
    gender: 'Gender',
    male: 'Male',
    female: 'Female',
    formerName: 'Former Name',
    email: 'Email',
    telephoneNumber: 'Phone Number',
    address: 'Address',
    address2: 'Address 2',
    city: 'City',
    province: 'Province',
    postalCode: 'Postal Code',
  },
  signature: {
    header: 'Signatures',
    signatures: 'Signatures',
    type: {
      electronic: 'Encrypted signature key',
    },
    signBy: 'Signed by',
    [ParticipantRoles.AGENT]: {
      one: 'Advisor',
      other: 'Advisors',
    },
    [ParticipantRoles.INSURED]: {
      one: 'Proposed Life Insured',
      other: 'Proposed Life Insureds',
    },
    [ParticipantRoles.LEGAL_GUARDIAN]: {
      one: 'Legal Guardian',
      other: 'Legal Guardians',
    },
    [ParticipantRoles.OFFICER]: {
      one: 'Officer',
      other: 'Officers',
    },
    [ParticipantRoles.OWNER_PERSON]: {
      one: 'Owner',
      other: 'Owners',
    },
    [ParticipantRoles.PAYER]: {
      one: 'Payor',
      other: 'Payors',
    },
    [ParticipantRoles.SECONDARY_PAYER]: {
      one: 'Payor',
      other: 'Payors',
    },
    [ParticipantRoles.SIGNING_AUTHORITY]: {
      one: 'Signing Authority',
      other: 'Signing Authorities',
    },
    [ParticipantRoles.SOLE_PROPRIETOR]: {
      one: 'Sole Proprietor',
      other: 'Sole Proprietors',
    },
    [ParticipantRoles.TRANSLATOR]: {
      one: 'Translator',
      other: 'Translators',
    },
    [ParticipantRoles.TRUSTEE]: {
      one: 'Trustee',
      other: 'Trustees',
    },
    [ParticipantRoles.ANNUITANT]: {
      one: 'Annuitant',
      other: 'Annuitants',
    },
    [ParticipantRoles.CONTRIBUTOR]: {
      one: 'Contributor',
      other: 'Contributors',
    },
    [ParticipantRoles.SECONDARY_CONTRIBUTOR]: {
      one: 'Contributor',
      other: 'Contributors',
    },
    date: 'Date of signature',
    electronicInfo:
      'I understand that my encrypted signature key creates a legal obligation in the same way as a signature on a paper document.',
    defaultLabel: {
      [ParticipantRoles.AGENT]: 'Signature of Advisor',
      [ParticipantRoles.INSURED]: 'Signature of Proposed Life Insured',
      [ParticipantRoles.LEGAL_GUARDIAN]: 'Signature of Legal Guardian',
      [ParticipantRoles.OFFICER]: 'Signature of Officer',
      [ParticipantRoles.OWNER_PERSON]: 'Signature of Owner',
      [ParticipantRoles.PAYER]: 'Signature of Payor',
      [ParticipantRoles.SECONDARY_PAYER]: 'Signature of Payor',
      [ParticipantRoles.SIGNING_AUTHORITY]: 'Signature of Signing Authority',
      [ParticipantRoles.SOLE_PROPRIETOR]: 'Signature of Sole Proprietor',
      [ParticipantRoles.TRANSLATOR]: 'Signature of Translator',
      [ParticipantRoles.TRUSTEE]: 'Signature of Trustee',
      [ParticipantRoles.ANNUITANT]: 'Signature of Annuitant',
      [ParticipantRoles.CONTRIBUTOR]: 'Signature of Contributor',
      [ParticipantRoles.SECONDARY_CONTRIBUTOR]: 'Signature of Contributor',
    },
    encrypted: 'Encrypted',
  },
  submissionDate: 'Application completed and submitted on: {{submissionDate}}',
  insuranceFirmInformation: {
    header: 'Insurance Company Information',
    name: 'Name',
    address: 'Mailing Address',
    primaryPhoneNumber: 'Primary Phone Number',
    tollFreePhoneNumber: 'Toll-free Phone Number',
    email: 'Email',
    website: 'Website',
  },
  payorInformation: {
    header: 'Payor Information',
    fullName: 'Full Name',
    title: 'Title',
    firstName: 'First Name',
    middleName: 'Middle Name',
    lastName: 'Last Name',
    suffix: 'Suffix',
    address: 'Address',
    phoneNumber: 'Phone Number',
    email: 'Email',
    bankNumber: 'Bank Number',
    address2: 'Address 2',
    city: 'City',
    provinceOrState: 'Province', // FIXME: Determine province or state
    postalCode: 'Postal Code',
  },
  ownerInformation: {
    header: 'Owner Information',
    fullName: 'Full Name',
    title: 'Title',
    firstName: 'First Name',
    middleName: 'Middle Name',
    lastName: 'Last Name',
    suffix: 'Suffix',
    dateOfBirth: 'Date of Birth',
    address: 'Address',
    phoneNumber: 'Phone Number',
    email: 'Email',
    address2: 'Address 2',
    city: 'City',
    provinceOrState: 'Province', // FIXME: Determine province or state
    postalCode: 'Postal Code',
    applicationCreatedOn: `Application created on`,
    applicationCompletedOn: `Application completed on`,
    insuredName: `Name of Insured`,
    insuredEmail: `Insured's Email`,
    referenceNumber: `Reference Number`,
    id: `ID`,
  },
  carrierInformation: {
    applicationCreatedOn: `Application created on`,
    applicationCompletedOn: `Application completed on`,
    insuredName: `Name of Insured`,
    insuredEmail: `Insured's Email`,
    referenceNumber: `Reference Number`,
    id: `ID`,
  },
};
