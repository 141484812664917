import { ReactElement, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { PdfContainer } from './Components/PdfContainer';
import { setDynamicPdfTranslations } from './Localization/Localizer';
import { DynamicPdfProps } from './types';

function DynamicPdf(props: DynamicPdfProps): ReactElement {
  const { pdfTextOverrides = {} } = props;

  useEffect(() => {
    setDynamicPdfTranslations(pdfTextOverrides);
  }, [pdfTextOverrides]);
  return (
    <Routes>
      <Route path={'/:type/:applicationId'} element={<PdfContainer {...props} />} />
    </Routes>
  );
}

export default DynamicPdf;
