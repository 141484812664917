import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { ReactElement } from 'react';

import { Box, Grid } from '@breathelife/mui';
import { DynamicPdfComponentName } from '@breathelife/types';

import usePdfContext from '../../../../Context/usePdfContext';
import { translate } from '../../../../Localization/Localizer';
import { formatDateWithTime } from '../../../../helpers/date';
import getComponentSettings from '../../../../helpers/getComponentSettings';
import { QuestionAnswerGroupA } from './Styles/PdfQuestionAnswerGroupA';

// Configure dayjs to support YYYY-MM-DD hh:mm ET format
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat);

export const SUBMISSION_DATE_TEST_IDS = {
  DATE_FIELD: 'SubmissionDate:dateField',
};

export function SubmissionDate(): ReactElement | null {
  const { application, timezone, componentSelection = [] } = usePdfContext();
  const { submissionDate, lang: language } = application;

  const submissionDateSettings = getComponentSettings(componentSelection, DynamicPdfComponentName.SubmissionDate);
  const { displayFullDate = false } = submissionDateSettings;

  if (!submissionDate) {
    return null;
  }
  const regionTimezone = timezone ? timezone : 'America/Toronto';
  const formattedSubmissionDate = formatDateWithTime(submissionDate, language, regionTimezone, displayFullDate);
  const submissionDateText = translate('submissionDate', { locale: language, submissionDate: formattedSubmissionDate });

  return (
    <Box mb={2}>
      <Grid container spacing={3} data-testid='SubmissionDate'>
        <Grid item xs={6}>
          <QuestionAnswerGroupA
            testID={SUBMISSION_DATE_TEST_IDS.DATE_FIELD}
            value={submissionDateText}
          ></QuestionAnswerGroupA>
        </Grid>
      </Grid>
    </Box>
  );
}
