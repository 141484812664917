import { ReactElement, Fragment } from 'react';

import { ColumnContainer, ColumnTitle, IndentedBlock } from './Styles/PdfVariantBStyles';

export function renderColumnBlockWithIndent(title: string, ...contents: string[]): ReactElement {
  return (
    <ColumnContainer>
      <ColumnTitle>{title}:</ColumnTitle>
      <IndentedBlock>{renderColumnBlock(...contents)}</IndentedBlock>
    </ColumnContainer>
  );
}

export function renderColumnBlock(...contents: string[]): ReactElement {
  return (
    <Fragment>
      {contents.map((content: string, index: number) => (
        <div key={`${index}_${content}`}>{content}</div>
      ))}
    </Fragment>
  );
}
