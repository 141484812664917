import dayjs from 'dayjs';
import _ from 'lodash';
import { Fragment, ReactElement } from 'react';

import { Box, Grid } from '@breathelife/mui';
import { AnnuitantEntity } from '@breathelife/types';
import { PdfFieldGroupHeader, PdfPaddedGridItem, PdfPaddedGrid } from '@breathelife/ui-components';

import usePdfContext from '../../../../Context/usePdfContext';
import { translate } from '../../../../Localization/Localizer';
import { QuestionAnswerGroupA } from './Styles/PdfQuestionAnswerGroupA';

export function AnnuitantInformation(): ReactElement | null {
  const { application, annuitantInformation, useIndividualNameFields = false } = usePdfContext();

  const { lang: language } = application;

  const {
    title,
    firstName,
    middleName,
    lastName,
    dateOfBirth,
    gender,
    formerName,
    street,
    apartment,
    city,
    province,
    postalCode,
  } = annuitantInformation || ({} as AnnuitantEntity);

  if (!firstName && !lastName) {
    return null;
  }

  const capitalizedTitle = title ? _.capitalize(title) : '';

  const fullName = [capitalizedTitle, firstName, middleName, lastName].filter(Boolean).join(' ');

  return (
    <Box mb={3}>
      <PdfFieldGroupHeader data-testid='annuitantInformation'>
        {translate('annuitantInformation.header', { locale: language })}
      </PdfFieldGroupHeader>

      <PdfPaddedGrid container spacing={3} alignItems='flex-start'>
        {useIndividualNameFields && (
          <Fragment>
            {!!capitalizedTitle && (
              <Grid item xs={4}>
                <QuestionAnswerGroupA
                  labelText={translate('annuitantInformation.title', { locale: language })}
                  value={capitalizedTitle}
                ></QuestionAnswerGroupA>
              </Grid>
            )}
            {!!firstName && (
              <PdfPaddedGridItem item xs={4}>
                <QuestionAnswerGroupA
                  labelText={translate('annuitantInformation.firstName', { locale: language })}
                  value={firstName}
                ></QuestionAnswerGroupA>
              </PdfPaddedGridItem>
            )}
            {!!middleName && (
              <PdfPaddedGridItem item xs={4}>
                <QuestionAnswerGroupA
                  labelText={translate('annuitantInformation.middleName', { locale: language })}
                  value={middleName}
                ></QuestionAnswerGroupA>
              </PdfPaddedGridItem>
            )}
            {!!lastName && (
              <PdfPaddedGridItem item xs={4}>
                <QuestionAnswerGroupA
                  labelText={translate('annuitantInformation.lastName', { locale: language })}
                  value={lastName}
                ></QuestionAnswerGroupA>
              </PdfPaddedGridItem>
            )}
          </Fragment>
        )}
        {!useIndividualNameFields && (
          <PdfPaddedGridItem item xs={4}>
            <QuestionAnswerGroupA
              labelText={translate('annuitantInformation.fullName', { locale: language })}
              value={fullName}
            ></QuestionAnswerGroupA>
          </PdfPaddedGridItem>
        )}
        {gender && (
          <PdfPaddedGridItem item xs={4}>
            <QuestionAnswerGroupA
              labelText={translate('annuitantInformation.gender', { locale: language })}
              value={translate(`annuitantInformation.${gender.toLowerCase()}`, { locale: language })}
            ></QuestionAnswerGroupA>
          </PdfPaddedGridItem>
        )}
        {dateOfBirth && (
          <PdfPaddedGridItem item xs={4}>
            <QuestionAnswerGroupA
              labelText={translate('annuitantInformation.dateOfBirth', { locale: language })}
              value={dayjs.utc(dateOfBirth).format('YYYY-MM-DD')}
            ></QuestionAnswerGroupA>
          </PdfPaddedGridItem>
        )}
        {!!formerName && (
          <PdfPaddedGridItem item xs={4}>
            <QuestionAnswerGroupA
              labelText={translate('annuitantInformation.formerName', { locale: language })}
              value={formerName}
            ></QuestionAnswerGroupA>
          </PdfPaddedGridItem>
        )}
        {!!street && (
          <PdfPaddedGridItem item xs={4}>
            <QuestionAnswerGroupA
              labelText={translate('annuitantInformation.address', { locale: language })}
              value={street}
            ></QuestionAnswerGroupA>
          </PdfPaddedGridItem>
        )}
        {!!apartment && (
          <PdfPaddedGridItem item xs={4}>
            <QuestionAnswerGroupA
              labelText={translate('annuitantInformation.address2', { locale: language })}
              value={apartment}
            ></QuestionAnswerGroupA>
          </PdfPaddedGridItem>
        )}
        {!!city && (
          <PdfPaddedGridItem item xs={4}>
            <QuestionAnswerGroupA
              labelText={translate('annuitantInformation.city', { locale: language })}
              value={city}
            ></QuestionAnswerGroupA>
          </PdfPaddedGridItem>
        )}
        {!!province && (
          <PdfPaddedGridItem item xs={4}>
            <QuestionAnswerGroupA
              labelText={translate('annuitantInformation.province', { locale: language })}
              value={province}
            ></QuestionAnswerGroupA>
          </PdfPaddedGridItem>
        )}
        {!!postalCode && (
          <PdfPaddedGridItem item xs={4}>
            <QuestionAnswerGroupA
              labelText={translate('annuitantInformation.postalCode', { locale: language })}
              value={postalCode}
            ></QuestionAnswerGroupA>
          </PdfPaddedGridItem>
        )}
      </PdfPaddedGrid>
    </Box>
  );
}
