import { ParticipantRoles, DynamicPdfType, ESignSigner } from '@breathelife/types';

import { Signers } from '../types';

// These are the roles for which fields will be generated in specified pdf types.
// The order they are listed is the order fields will be added to the pdf.
const DynamicPdfTypeSignerRoleLookup: Record<DynamicPdfType, ParticipantRoles[]> = {
  [DynamicPdfType.advisor]: [ParticipantRoles.AGENT],
  [DynamicPdfType.insured]: [
    ParticipantRoles.INSURED,
    ParticipantRoles.OWNER_PERSON,
    ParticipantRoles.LEGAL_GUARDIAN,
    ParticipantRoles.SIGNING_AUTHORITY,
    ParticipantRoles.SOLE_PROPRIETOR,
    ParticipantRoles.TRUSTEE,
    ParticipantRoles.OFFICER,
    ParticipantRoles.PAYER,
    ParticipantRoles.TRANSLATOR,
    ParticipantRoles.AGENT,
    ParticipantRoles.ANNUITANT,
    ParticipantRoles.CONTRIBUTOR,
  ],
  [DynamicPdfType.carrier]: [],
};

export async function generateSignees(pdfType: DynamicPdfType, eSignSigners: ESignSigner[]): Promise<Signers> {
  const signers: Signers = {};

  DynamicPdfTypeSignerRoleLookup[pdfType].forEach((participantRole) =>
    (signers[participantRole] = eSignSigners.filter((signer) =>
      signer.roles.some((role) => role.type === participantRole),
    )).sort(
      (signerA, signerB) =>
        (signerA.roles.find((role) => role.type === participantRole)?.position || 0) -
        (signerB.roles.find((role) => role.type === participantRole)?.position || 0),
    ),
  );

  return signers;
}
