import { ReactElement, Fragment } from 'react';

import { Language, ParticipantRoles, ESignSigner, SignatureFieldType, SigneeLabel } from '@breathelife/types';
import { PdfFieldGroupHeader, PdfFieldText } from '@breathelife/ui-components';

import usePdfContext from '../../../../Context/usePdfContext';
import { translate } from '../../../../Localization/Localizer';
import { generateOneSpanTextTag } from '../../../../helpers/signatureHelpers';
import { QuestionAnswerGroupA } from './Styles/PdfQuestionAnswerGroupA';

type ESignatureProps = {
  signeeType: ParticipantRoles;
  fieldType?: SignatureFieldType;
};

export function ESignature(props: ESignatureProps): ReactElement | null {
  const { signeeType, fieldType } = props;
  const { application, signatureData, displaySigneeLabel = SigneeLabel.default } = usePdfContext();

  if (!signatureData) {
    return null;
  }

  const { lang: language } = application;

  const { signers, signatureDetails } = signatureData;

  const signees = signers[signeeType];

  if (!signees || signees.length === 0) {
    return null;
  }

  const signatureTitle = signatureDetails && signatureDetails[signeeType]?.title;
  const signatureText = signatureDetails && signatureDetails[signeeType]?.text;

  // i18n-js supports pluralization by specifying a 'count' property in the options.
  // If the count is '1', the corresponding localization value for 'one' will be used.
  // If the count is anything greater, the localization value for 'other' will be used.
  // The case of 'zero' is optional.
  // Specifying 'count' in the options is only necessary if the localization has pluralization.
  const signeeCount = signees.length;

  return (
    <Fragment>
      <PdfFieldGroupHeader>
        {signatureTitle
          ? signatureTitle[language]
          : translate(`signature.${signeeType}`, { locale: language, count: signeeCount })}
      </PdfFieldGroupHeader>
      {signatureText && <PdfFieldText>{signatureText[language]}</PdfFieldText>}

      {signees &&
        signees.map((signee: ESignSigner, index: number) => (
          <QuestionAnswerGroupA
            key={index}
            labelText={generateLabelText(signee, index + 1, displaySigneeLabel, signeeType, language, signeeCount)}
            value={generateOneSpanTextTag(signee.tag, fieldType ?? SignatureFieldType.capture, {
              x: 120,
              y: 30,
            })}
            isSignature
          />
        ))}
    </Fragment>
  );
}

function generateLabelText(
  signee: ESignSigner,
  identifier: number,
  displaySigneeLabel: SigneeLabel,
  signeeType: string,
  language: Language,
  signeeCount: number,
): string {
  if (displaySigneeLabel === SigneeLabel.name) {
    return `${signee.firstName} ${signee.lastName}`;
  }
  const defaultLabelTextKey = `signature.defaultLabel.` + signeeType;

  if (displaySigneeLabel === SigneeLabel.default) {
    if (signeeCount === 1) {
      return translate(defaultLabelTextKey, { locale: language });
    }
    return translate(defaultLabelTextKey, { locale: language }) + ` ${identifier}`;
  }

  return '';
}
