import { PdfVariant } from '@breathelife/types';

import { ReactElementList } from '../../types';
import FieldListA from './FieldVariantA/FieldListA';
import FieldListB from './FieldVariantB/FieldListB';

export const fieldVariantLookUp: {
  [key: string]: ReactElementList;
} = {
  [PdfVariant.A]: FieldListA,
  [PdfVariant.B]: FieldListB,
};
