import ReactHtmlParser from 'html-react-parser';
import { ReactElement, Fragment } from 'react';

import { Grid } from '@breathelife/mui';
import { RenderingField, RenderingOptionField } from '@breathelife/questionnaire-engine';

import usePdfContext from '../../../Context/usePdfContext';
import { formatTextFieldValue, getFieldLabel } from '../../../helpers/fieldHelpers';
import { GridContainer, GridItem, SequenceNumber, VariantText } from './Styles/FieldVariantBStyles';

type Props = {
  field: RenderingField;
  fieldIndex: string;
};

export function Money(props: Props): ReactElement | null {
  const { field, fieldIndex } = props;
  const { application, currency } = usePdfContext();

  const locale = application.lang;
  const label = getFieldLabel(field);

  return (
    <Fragment>
      {label ? (
        <GridContainer container direction='row'>
          <GridItem item xs={9}>
            <SequenceNumber>{fieldIndex}</SequenceNumber>
            <Grid>
              <VariantText>{ReactHtmlParser(label)}</VariantText>
              {field.text && <VariantText grey={80}>{ReactHtmlParser(field.text)}</VariantText>}
            </Grid>
          </GridItem>
          <Grid item xs={3}>
            <VariantText align='right' weight={800}>
              {formatTextFieldValue(field.value, field.type, locale, currency, (field as RenderingOptionField).options)}
            </VariantText>
          </Grid>
        </GridContainer>
      ) : (
        <Grid item xs={3}>
          <VariantText align='right' weight={800}>
            {formatTextFieldValue(field.value, field.type, locale, currency, (field as RenderingOptionField).options)}
          </VariantText>
        </Grid>
      )}
    </Fragment>
  );
}
