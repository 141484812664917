import _ from 'lodash';
import ReactHtmlParser from 'html-react-parser';
import { ReactElement, Fragment } from 'react';
import styled, { css } from 'styled-components';

import { Box } from '@breathelife/mui';
import { RenderingQuestion } from '@breathelife/questionnaire-engine';
import { DynamicPdfComponentName, PdfVariant } from '@breathelife/types';
import { PdfLabelText, PdfFieldGroupHeader } from '@breathelife/ui-components';

import usePdfContext from '../../../../Context/usePdfContext';
import getComponentSettings from '../../../../helpers/getComponentSettings';
import { FieldWrapperA } from '../../../PdfFieldGeneration/FieldVariantA/FieldWrapperA';
import { isUnansweredField } from '../../../../helpers/fieldHelpers';
import { fieldVariantLookUp } from '../../../PdfFieldGeneration/FieldVariantLookUp';

type Props = {
  fieldGroups: RenderingQuestion[];
};

const FieldContainer = styled.div<{ pageBreakInside: string }>`
  ${(props) => {
    return css`
      break-inside: ${props.pageBreakInside};
    `;
  }}
`;

const FieldChunk = styled(Box)`
  break-inside: avoid;
`;

export const FIELD_GROUP_TEST_IDS = {
  FIELD_GROUP_TITLE: 'FieldGroup:fieldGroupTitle',
  FIELD_GROUP_TEXT: 'FieldGroup:fieldGroupText',
};

export function FieldGroups(props: Props): ReactElement | null {
  const { fieldGroups } = props;
  const { componentSelection = [], variant } = usePdfContext();
  const questionnaireSettings = getComponentSettings(componentSelection, DynamicPdfComponentName.Questionnaire);
  const { avoidFieldGroupPageBreak = true } = questionnaireSettings;
  const pageBreakInside = avoidFieldGroupPageBreak ? 'avoid' : 'auto';
  const pdfVariant = variant || PdfVariant.A;

  return (
    <Fragment>
      {fieldGroups.map((fieldGroup: RenderingQuestion) => {
        const { title: fieldGroupTitle, text: fieldGroupText, id, visible, fields } = fieldGroup;
        if (!visible) return null;

        // Grouping fields in sets of two so page breaks can occur between each row of fields as needed
        // Avoiding page breaks can't be set within the grid itself so the field grid needs to be segmented
        // Otherwise page breaks can occur directly on a field value
        const filteredFields = fields.filter((field) => {
          if (!field.readOnly && (!field.visible || isUnansweredField(field))) {
            return false;
          }
          if (!fieldVariantLookUp[pdfVariant][field.type]) {
            return false;
          }
          return true;
        });
        const fieldChunks = _.chunk(filteredFields, 2);

        return (
          <Fragment key={id}>
            <FieldContainer key={id} pageBreakInside={pageBreakInside}>
              {fieldGroupTitle && (
                <Box mb={1}>
                  <PdfFieldGroupHeader data-testid={FIELD_GROUP_TEST_IDS.FIELD_GROUP_TITLE}>
                    {ReactHtmlParser(fieldGroupTitle)}
                  </PdfFieldGroupHeader>
                </Box>
              )}
              {fieldGroupText && (
                <Box mb={2} mt={-1}>
                  <PdfLabelText data-testid={FIELD_GROUP_TEST_IDS.FIELD_GROUP_TEXT}>
                    {ReactHtmlParser(fieldGroupText)}
                  </PdfLabelText>
                </Box>
              )}
              {fieldChunks.map((fieldChunk, index) => (
                <FieldChunk mb={2} key={id + `_` + index}>
                  <FieldWrapperA fields={fieldChunk} />
                </FieldChunk>
              ))}
            </FieldContainer>
          </Fragment>
        );
      })}
    </Fragment>
  );
}
