import { PdfVariant } from '@breathelife/types';

import { ReactElementList } from '../../../types';
import ComponentListA from './ComponentVariantsA/ComponentListA';
import ComponentListB from './ComponentVariantsB/ComponentListB';

export const componentVariantLookUp: {
  [key: string]: ReactElementList;
} = {
  [PdfVariant.A]: ComponentListA,
  [PdfVariant.B]: ComponentListB,
};
