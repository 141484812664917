import { ReactElement, Fragment } from 'react';

import { RenderingField } from '@breathelife/questionnaire-engine';
import { DynamicPdfComponentName } from '@breathelife/types';

import usePdfContext from '../../../Context/usePdfContext';
import getComponentSettings from '../../../helpers/getComponentSettings';
import { FieldFactory } from '../FieldFactory';
import { PdfPaddedGrid } from '@breathelife/ui-components';

type Props = {
  fields: RenderingField[];
};

export function FieldWrapperA(props: Props): ReactElement | null {
  const { fields } = props;

  const { componentSelection = [] } = usePdfContext();

  const questionnaireSettings = getComponentSettings(componentSelection, DynamicPdfComponentName.Questionnaire);
  const { alignFieldsTop } = questionnaireSettings;

  return (
    <Fragment>
      <PdfPaddedGrid container spacing={3} alignItems={alignFieldsTop ? 'flex-start' : 'flex-end'}>
        {fields.map((field: RenderingField) => (
          <Fragment key={field.id}>
            <FieldFactory field={field} />
          </Fragment>
        ))}
      </PdfPaddedGrid>
    </Fragment>
  );
}
