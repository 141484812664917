import { ReactElement } from 'react';

import { Language } from '@breathelife/types';

import { translate } from '../../Localization/Localizer';

type Props = {
  language: Language;
};

export function CryptoStamp(props: Props): ReactElement {
  const { language } = props;

  return (
    <svg version='1.1' width='108px' height='100px' viewBox='0 0 108px 100px' xmlns='http://www.w3.org/2000/svg'>
      <g transform='scale(0.6)'>
        <path
          display='inline'
          fill='none'
          fillOpacity='1'
          stroke='#ff9595'
          strokeWidth='16'
          strokeMiterlimit='4'
          strokeDasharray='none'
          strokeOpacity='1'
          d='M 350,100 A 250.00001,250.00001 0 0 0 102.66406,313.88672 L 577.66992,246.77148 A 250.00001,250.00001 0 0 0 350,100 Z M 596.58594,390.53906 124.23828,457.2793 A 250.00001,250.00001 0 0 0 350,600 250.00001,250.00001 0 0 0 596.58594,390.53906 Z'
          transform='scale(0.26458333)'
        />
        <path
          display='inline'
          fill='none'
          fillOpacity='1'
          stroke='#ff9595'
          strokeWidth='8'
          strokeMiterlimit='4'
          strokeDasharray='none'
          strokeOpacity='1'
          d='M 350,177.60547 A 172.39374,172.39374 0 0 0 184.42188,302.33398 L 495.97852,258.31445 A 172.39374,172.39374 0 0 0 350,177.60547 Z m 164.25977,224.56641 -307.625,43.46484 A 172.39374,172.39374 0 0 0 350,522.39453 172.39374,172.39374 0 0 0 514.25977,402.17188 Z'
          transform='scale(0.26458333)'
        />
        <path
          display='inline'
          fill='none'
          fillOpacity='0.548387'
          stroke='#ff9595'
          strokeWidth='4.23333'
          strokeMiterlimit='4'
          strokeDasharray='none'
          strokeOpacity='1'
          d='M 169.75116,62.771741 12.562311,84.981236 17.929941,122.96858 175.11879,100.75909 Z'
        />
        {language === Language.fr ? (
          <text
            fontStyle='normal'
            fontWeight='normal'
            fontSize='24px'
            fontFamily='sans-serif'
            fill='#ff9595'
            fillOpacity='1'
            stroke='none'
            strokeWidth='0.264583'
            x='33'
            y='113'
            transform='rotate(-8)'
          >
            <tspan
              fontStyle='normal'
              fontVariant='normal'
              fontWeight='bold'
              fontStretch='normal'
              fontSize='24px'
              fontFamily='Futura'
              strokeWidth='0.264583'
              fill='ff9595'
              fillOpacity='1'
              x='33'
              y='113'
            >
              {translate('signature.encrypted', { locale: Language.fr })}
            </tspan>
          </text>
        ) : (
          <text
            fontStyle='normal'
            fontWeight='normal'
            fontSize='24px'
            fontFamily='sans-serif'
            fill='#ff9595'
            fillOpacity='1'
            stroke='none'
            strokeWidth='0.264583'
            x='13'
            y='113'
            transform='rotate(-8)'
          >
            <tspan
              fontStyle='normal'
              fontVariant='normal'
              fontWeight='bold'
              fontStretch='normal'
              fontSize='24px'
              fontFamily='Futura'
              strokeWidth='0.264583'
              fill='ff9595'
              fillOpacity='1'
              x='13'
              y='113'
            >
              {translate('signature.encrypted', { locale: Language.en })}
            </tspan>
          </text>
        )}
      </g>
    </svg>
  );
}
