import { ReactElement } from 'react';

import { countryOptions, provinceOptions } from '@breathelife/insurance-form-builder';
import { ProposedInsuredEntity } from '@breathelife/types';

import usePdfContext from '../../../../Context/usePdfContext';
import { translate } from '../../../../Localization/Localizer';
import { formatPhoneNumber, formatFullDate, formatAddress, formatTimeOfDay } from './Helpers/variantPdfHelper';
import { renderColumnBlock, renderColumnBlockWithIndent } from './RenderColumn';
import { ColumnContainer, Column, Section, Separator, Subtitle, Title } from './Styles/PdfVariantBStyles';

export function ProposedInsuredInformation(): ReactElement | null {
  const { proposedInsuredInformation, application, isSmoker } = usePdfContext();
  const { lang: language } = application;

  const {
    title,
    firstName,
    middleName,
    lastName,
    suffix,
    dateOfBirth,
    gender,
    email,
    phoneNumber,
    isTobaccoUser,
    previousFirstName,
    previousLastName,
    occupation,
    canadianCitizen,
    birthCountry,
    birthProvince,
    address,
    address2,
    city,
    provinceOrState,
    postalCode,
    contactTimeOfDay,
    tobaccoMostRecentUsage,
    marijuanaMostRecentUsage,
  } = proposedInsuredInformation?.[0] || ({} as ProposedInsuredEntity); // TODO: Handle multi-insured

  if (!(Boolean(firstName) && Boolean(lastName))) {
    return null;
  }

  const fullName = [title, firstName, middleName, lastName, suffix].filter(Boolean).join(' ');
  const previousFullName = [previousFirstName, previousLastName].filter(Boolean).join(' ');
  const fullAddress = formatAddress(address ?? '', address2 ?? '', city ?? '', provinceOrState ?? '', postalCode ?? '');
  const filterCountry = countryOptions.find((country) => (country.id === birthCountry ? country : ''));
  const filterProvince = provinceOptions.find((province) => (province.id === birthProvince ? province : ''));

  const isInsuredTobaccoUser = isSmoker({ isTobaccoUser, tobaccoMostRecentUsage, marijuanaMostRecentUsage });

  return (
    <Section>
      <Title>Renseignements personnels</Title>
      <Separator />
      <Subtitle>Personne assurée</Subtitle>
      <Separator />
      <ColumnContainer>
        <Column width={65}>
          <div>
            {'Prénom et nom:'} <strong>{fullName}</strong>
          </div>
          {renderColumnBlockWithIndent('Adresse', fullAddress)}
          {renderColumnBlockWithIndent('Courriel', email ?? '')}
          {renderColumnBlockWithIndent('Tél ', formatPhoneNumber(phoneNumber ?? ''))}
        </Column>

        <Column width={35}>
          {renderColumnBlock(
            `Date de naissance: ${dateOfBirth ? formatFullDate(dateOfBirth) : ''}`,
            `Sexe: ${translate(`proposedInsuredInformation.${gender}`, { locale: language })}`,
            isInsuredTobaccoUser
              ? `Fumeur: ${translate('proposedInsuredInformation.yes', { locale: language })}`
              : `Fumeur: ${translate('proposedInsuredInformation.no', { locale: language })}`,
            `Citoyen canadien: ${translate(`proposedInsuredInformation.${canadianCitizen}`, { locale: language })}`,
            `Pays de naissance: ${filterCountry?.text[language]}`,
            birthProvince ? `Province de naissance: ${filterProvince?.text[language]}` : '',
            `Métier ou profession: ${occupation}`,
            previousFullName && `Prénom et nom précédent: ${previousFullName}`,
            `Meilleur temps pour me rejoindre: ${formatTimeOfDay(contactTimeOfDay ?? '')}`,
          )}
        </Column>
      </ColumnContainer>
    </Section>
  );
}
