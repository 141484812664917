import { ESignSigner, Language, SignatureFieldType, SigneeLabel } from '@breathelife/types';

import { translate } from '../Localization/Localizer';
import { AutofieldFieldType, InputFieldType } from '../types';

type Size = {
  x: number;
  y: number;
};

type Position = Size;

// @TODO: move this to onespan.action.ts
/**
 * Helper function to generate a OneSpan Text Tag to be embedded in the dynamic-pdf
 *
 * @param signerTag The signature tag to target the intended signer. for ex: SIGNER01, SIGNER16
 * @param fieldType One of the field types specified by OneSpan (signature, initials, capture, etc.)
 * @param size Size of the field (in points x 1.3)
 * @param offset Offset (in points x 1.3) to be applied in positioning the field relative to the top left corner of the Text Tag
 * @param group The radio group to which the field will belong (Input Field only)
 * @param value The fields default value (Input Field only)
 * @param options The list of values available for a List field (Input Field only)
 * @param maxLen The maximum permitted value for the field (Input Field only)
 * @param fieldName Name of the field that will be created by the Text Tag
 * @returns Valid OneSpan Text Tag
 */
export function generateOneSpanTextTag(
  signerTag: string,
  fieldType: SignatureFieldType | AutofieldFieldType,
  size: Size,
  offset?: Position,
  fieldName?: string,
): string;
export function generateOneSpanTextTag(
  signerTag: string,
  fieldType: InputFieldType,
  size: Size,
  offset?: Position,
  group?: string,
  value?: string,
  options?: string[],
  maxLen?: number,
  fieldName?: string,
): string;
export function generateOneSpanTextTag(
  signerTag: string,
  fieldType: any,
  size: Size,
  offset?: Position,
  group?: string,
  value?: string,
  options?: string[],
  maxLen?: number,
  fieldName?: string,
): string {
  /* Example output format: {{esl:insured1:capture:size(200,50)}} */

  if (Object.values(InputFieldType).includes(fieldType)) {
    return (
      `{{esl` +
      `${fieldName || ''}:` +
      `${signerTag}:` +
      `${fieldType}:` +
      `size(${size.x},${size.y})` +
      `${offset ? `,offset(${offset.x},${offset.y})` : ''}` +
      `${group ? `,group("${group}")` : ''}` +
      `${value ? `,value("${value}")` : ''}` +
      `${options ? createInputFieldOptions(options) : ''}` +
      `${maxLen ? `,maxLen(${maxLen})` : ''}` +
      `}}`
    );
  }
  return (
    `{{esl` +
    `${fieldName || ''}:` +
    `${signerTag}:` +
    `${fieldType}:` +
    `size(${size.x},${size.y})` +
    `${offset ? `,offset(${offset.x},${offset.y})` : ''}` +
    `}}`
  );
}

// Return a well-formed options parameter given an array of options
// Eg. options = ["Red", "Blue"];
// Return ',options("Red","Blue")'
function createInputFieldOptions(options: string[]): string {
  let inputFieldOptions = ',options(';

  options.forEach((option) => {
    inputFieldOptions += '"' + option + '",';
  });

  let completeInputFieldOptions = inputFieldOptions.slice(0, -1);
  completeInputFieldOptions += ')';

  return completeInputFieldOptions;
}

export function generateLabelText(
  signee: ESignSigner,
  identifier: number,
  displaySigneeLabel: SigneeLabel,
  signeeType: string,
  language: Language,
  signeeCount: number,
): string {
  const defaultLabelTextKey = `signature.defaultLabel.` + signeeType;
  const defaultSigneeLabel =
    signeeCount === 1
      ? translate(defaultLabelTextKey, { locale: language })
      : translate(defaultLabelTextKey, { locale: language }) + ` ${identifier}`;

  const signeeLabelLookup = {
    [SigneeLabel.name]: `${signee.firstName} ${signee.lastName}`,
    [SigneeLabel.default]: defaultSigneeLabel,
    [SigneeLabel.none]: '',
  };

  return signeeLabelLookup[displaySigneeLabel];
}
