import ReactHtmlParser from 'html-react-parser';
import { ReactElement } from 'react';

import { Grid } from '@breathelife/mui';

import { GridContainer, GridItem, GridPropsWithText, SequenceNumber, VariantText } from '../Styles/FieldVariantBStyles';

export function LabelGrid(props: GridPropsWithText): ReactElement {
  const { text, fieldIndex, subtitle, ...gridProps } = props;
  return (
    <GridContainer container direction='row' {...gridProps}>
      <GridItem item xs={9}>
        <SequenceNumber>{fieldIndex}</SequenceNumber>
        <Grid>
          <VariantText>{ReactHtmlParser(text)}</VariantText>
          {subtitle && <VariantText grey={80}>{ReactHtmlParser(subtitle)}</VariantText>}
        </Grid>
      </GridItem>
    </GridContainer>
  );
}
