import { ReactElement, Fragment } from 'react';

import { RenderingField, RenderingOptionField } from '@breathelife/questionnaire-engine';
import { PdfPaddedGridItem } from '@breathelife/ui-components';

import usePdfContext from '../../../Context/usePdfContext';
import { formatTextFieldValue } from '../../../helpers/fieldHelpers';
import { QuestionAnswerGroupA } from '../../PdfComponents/ComponentVariants/ComponentVariantsA/Styles/PdfQuestionAnswerGroupA';

type Props = {
  field: RenderingField;
};

export function Autocomplete(props: Props): ReactElement | null {
  const { field } = props;
  const { application, currency } = usePdfContext();

  const locale = application.lang;

  return (
    <Fragment>
      <PdfPaddedGridItem item xs={6} data-testid='Autocomplete'>
        <QuestionAnswerGroupA
          fieldText={field.title}
          labelText={field.text}
          value={formatTextFieldValue(
            field.value,
            field.type,
            locale,
            currency,
            (field as RenderingOptionField).options,
          )}
        />
      </PdfPaddedGridItem>
    </Fragment>
  );
}
