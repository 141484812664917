import { useContext } from 'react';

import { InsuredPdfOptions } from '@breathelife/types';
import { PdfContext, PdfContextProps } from './PdfContext';

/* TODO: Consolidate InsuredPdfOptions into a single, pdf agnostic type - DEV-10481 */
const usePdfContext = (): PdfContextProps<InsuredPdfOptions> => {
  const pdfContext = useContext<PdfContextProps<InsuredPdfOptions>>(PdfContext);
  return pdfContext;
};

export default usePdfContext;
