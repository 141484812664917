import { ReactElement, Fragment } from 'react';

import usePdfContext from '../../../../Context/usePdfContext';
import { Header, DocumentTitle, Subtitle } from './Styles/PdfVariantBStyles';

export function PdfTitle(): ReactElement {
  const { proposedInsuredInformation } = usePdfContext();

  // TODO: Handle multi-insured
  const signeeName: string = `${proposedInsuredInformation?.[0]?.firstName ?? ''} ${
    proposedInsuredInformation?.[0]?.lastName ?? ''
  }`.trim();

  return (
    <Fragment>
      <Header>
        <DocumentTitle>Demande d'assurance personnalisée</DocumentTitle>
        <div>Pour</div>
        <Subtitle margin={'0'}>{signeeName}</Subtitle>
      </Header>
    </Fragment>
  );
}
