import ReactHtmlParser from 'html-react-parser';
import { ReactElement, Fragment } from 'react';

import { Grid } from '@breathelife/mui';
import { RenderingOptionField } from '@breathelife/questionnaire-engine';

import { translate } from '../../../Localization/Localizer';
import { optionIsSelected } from '../../../helpers/fieldHelpers';
import { RenderedFieldProps } from '../../../types';
import { LabelGrid } from './Helpers/LabelGrid';
import { GridContainer, GridItem, SequenceNumber, VariantText } from './Styles/FieldVariantBStyles';

export function CheckboxField(props: RenderedFieldProps): ReactElement | null {
  const { field, label, subtitle, fieldIndex, locale } = props;

  const options = (field as RenderingOptionField).options;

  if (!options) {
    throw new Error('There are no options to display a CheckboxField');
  }

  return (
    <Fragment>
      {options ? (
        <Fragment>
          {label && <LabelGrid text={label} subtitle={subtitle} fieldIndex={fieldIndex} />}
          {!!options.length &&
            options.map((option, index) => {
              if (option.id == 'none' || option.id == 'aucuneDeCesRéPonses') return null;
              const optionValue = optionIsSelected(option.id, field.value) ? translate('yes') : translate('no');
              return (
                <GridContainer
                  key={option.id}
                  container
                  direction='row'
                  style={{ paddingLeft: label ? '70px' : '35px' }}
                >
                  <GridItem item xs={9}>
                    <SequenceNumber minWidth={'12px'}>{`${String.fromCharCode(97 + index)}. `}</SequenceNumber>
                    <VariantText>{ReactHtmlParser(option.text)}</VariantText>
                  </GridItem>
                  <Grid item xs={3}>
                    <VariantText align='right' weight={800}>
                      {optionValue}
                    </VariantText>
                  </Grid>
                </GridContainer>
              );
            })}
        </Fragment>
      ) : (
        <GridContainer key={field.id} container direction='row'>
          <GridItem item xs={9}>
            <SequenceNumber>{fieldIndex}</SequenceNumber>
            <VariantText>{field.title}</VariantText>
          </GridItem>
          <Grid item xs={3}>
            <VariantText align='right' weight={800}>
              {String(field.value) ? translate('yes', { locale }) : translate('no', { locale })}
            </VariantText>
          </Grid>
        </GridContainer>
      )}
    </Fragment>
  );
}
