import I18n, { TranslateOptions } from 'i18n-js';
import _ from 'lodash';

import { DynamicPdfTextOverrides } from '@breathelife/types';

import LocalizedStrings from './';

//TODO: Need to strongly type the dynamic pdf overrides. Jira: DEV-9551
export function setDynamicPdfTranslations(carrierDynamicPdfTranslations: DynamicPdfTextOverrides): void {
  I18n.translations = {
    en: _.merge({}, LocalizedStrings.en, carrierDynamicPdfTranslations.en),
    fr: _.merge({}, LocalizedStrings.fr, carrierDynamicPdfTranslations.fr),
  };
}

export function translate(value: string, options?: TranslateOptions): string {
  return I18n.t(value, options);
}
