import { ReactElement } from 'react';

import { Box } from '@breathelife/mui';
import { PdfFieldGroupHeader, PdfPaddedGridItem, PdfPaddedGrid } from '@breathelife/ui-components';

import usePdfContext from '../../../../Context/usePdfContext';
import { translate } from '../../../../Localization/Localizer';
import { QuestionAnswerGroupA } from './Styles/PdfQuestionAnswerGroupA';

export function AdvisorInformation(): ReactElement {
  const { application, advisors } = usePdfContext();
  const { lang: language } = application;
  const advisor = advisors?.[0];
  const advisorName = `${advisor?.firstName || ''} ${advisor?.lastName || ''}`.trim();
  const advisorNumber = advisor?.customId || '';
  const advisorEmail = advisor?.email || '';
  const advisorPhoneNumber = advisor?.phoneNumber || '';

  return (
    <Box mb={3}>
      <PdfFieldGroupHeader data-testid='AdvisorInformation'>
        {' '}
        {translate('advisorInformation.header', { locale: language })}
      </PdfFieldGroupHeader>

      <PdfPaddedGrid container spacing={3} alignItems='flex-start'>
        <PdfPaddedGridItem item xs={4}>
          <QuestionAnswerGroupA
            labelText={translate('advisorInformation.name', { locale: language })}
            value={advisorName}
          />
        </PdfPaddedGridItem>

        <PdfPaddedGridItem item xs={4}>
          <QuestionAnswerGroupA
            labelText={translate('advisorInformation.number', { locale: language })}
            value={advisorNumber}
          />
        </PdfPaddedGridItem>

        <PdfPaddedGridItem item xs={4}>
          <QuestionAnswerGroupA
            labelText={translate('advisorInformation.email', { locale: language })}
            value={advisorEmail}
          />
        </PdfPaddedGridItem>

        <PdfPaddedGridItem item xs={4}>
          <QuestionAnswerGroupA
            labelText={translate('advisorInformation.phoneNumber', { locale: language })}
            value={advisorPhoneNumber}
          />
        </PdfPaddedGridItem>
      </PdfPaddedGrid>
    </Box>
  );
}
