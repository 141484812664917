import { YesNoValue, DynamicPdfFunctionOverrides, IsSmokerParams } from '@breathelife/types';

/** Returns the insured smoker status as true or false.
 * @param isTobaccoUser Available for all carriers (optional)
 * @param tobaccoMostRecentUsage Carrier specific overload (optional)
 * @param marijuanaMostRecentUsage Carrier specific overload (optional)
 * @returns boolean
 */
function isSmoker(props: IsSmokerParams): boolean {
  const { isTobaccoUser } = props;
  return isTobaccoUser === YesNoValue.yes ? true : false;
}

export const defaultPdfFunctions: DynamicPdfFunctionOverrides = {
  isSmoker,
};
