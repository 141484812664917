import { ReactElement } from 'react';

import { Box, Grid } from '@breathelife/mui';
import { GridPropsWithText, VariantText } from '../Styles/FieldVariantBStyles';

export function AnswerGrid(props: GridPropsWithText): ReactElement {
  const { text, ...gridProps } = props;
  return (
    <Grid {...gridProps}>
      <Box textAlign='right' pl={3}>
        <VariantText>{text}</VariantText>
      </Box>
    </Grid>
  );
}
