import axios from 'axios';

import config from '@breathelife/config/frontend';
import { addJwtIntoHeaderInterceptor, addTraceIntoHeaderInterceptor, Gateway } from '@breathelife/gateway';
import { getTraceId, monitoringInterceptor } from '@breathelife/monitoring-frontend';

const ApiService = axios.create();

ApiService.interceptors.request.use(addTraceIntoHeaderInterceptor(getTraceId()));
ApiService.interceptors.response.use((response) => response, monitoringInterceptor);

let authorizationInterceptorIndex: number;
// Add header authorization token
export function setAuthorizationInterceptor(token?: string): void {
  if (typeof authorizationInterceptorIndex !== 'undefined') {
    ApiService.interceptors.request.eject(authorizationInterceptorIndex);
  }

  authorizationInterceptorIndex = ApiService.interceptors.request.use(addJwtIntoHeaderInterceptor(token));
}

const releaseVersion = config.get<string>('git.commitSha');

export default new Gateway(releaseVersion, ApiService);
