import { FunctionComponent } from 'react';

import { NodeIdToAnswerPathMap, RenderingField, RenderingQuestionnaire } from '@breathelife/questionnaire-engine';
import {
  AdvisorEntity,
  AnnuitantEntity,
  Application,
  ApplicationPointOfSaleDecisions,
  DynamicPdfTextOverrides,
  DynamicPdfType,
  ESignSigner,
  IsoCountryCode,
  IsSmoker,
  Language,
  Localizable,
  OwnerEntity,
  ParticipantRoles,
  PayerEntity,
  PdfDocumentType,
  ProductAddon,
  ProductsEntity,
  ProposedInsuredEntity,
  SignatureFieldType,
  CarrierPdfOptions,
  InsuredPdfOptions,
  AdvisorPdfOptions,
} from '@breathelife/types';

// OneSpan Text Tag Field Type
export enum AutofieldFieldType {
  signerName = 'signerName',
  signature = 'signature',
  signerTitle = 'signerTitle',
  signerCompany = 'signerCompany',
  signerDate = 'signerDate',
}

// OneSpan Text Tag Field Type
export enum InputFieldType {
  textField = 'textField',
  textArea = 'textArea',
  list = 'list',
  radio = 'radio',
  checkbox = 'checkbox',
  label = 'label',
}

export type SignerDetails = {
  title?: Localizable;
  text?: Localizable;
};

export type SignatureDetails = Partial<Record<ParticipantRoles, SignerDetails>>;

export type Signers = Partial<Record<ParticipantRoles, ESignSigner[]>>;

export type DynamicPdfProps = {
  carrierPdfOptions: CarrierPdfOptions | null;
  insuredPdfOptions: InsuredPdfOptions | null;
  advisorPdfOptions: AdvisorPdfOptions | null;
  countryCode: IsoCountryCode;
  pdfTextOverrides?: DynamicPdfTextOverrides;
  signatureOptions?: { eSignatureFieldType?: SignatureFieldType };
};

export type PdfData = {
  renderingQuestionnaire: RenderingQuestionnaire;
  nodeIdToAnswerPathMap: NodeIdToAnswerPathMap;
  application: Application;
  currency: string;
  cryptoSignatureData?: CryptoSignatureData;

  // TODO: update signees or replace type with entity structure
  signatureData?: SignatureData;
  proposedInsuredInformation?: ProposedInsuredEntity[];
  productsEntity?: ProductsEntity;
  advisors?: AdvisorEntity[];
  addons?: ProductAddon[];
  annuitantInformation?: AnnuitantEntity | null;
  // Use applicantInformation for fields that represent insured or annuitant depending on line of business
  applicantInformation?: ProposedInsuredEntity[] | AnnuitantEntity | null;
  payorInformation?: PayerEntity[];
  ownerInformation?: OwnerEntity[];
  pdfType: DynamicPdfType;
  pointOfSaleDecisions?: ApplicationPointOfSaleDecisions | null;
  isSmoker: IsSmoker;
};

export type CryptoSignatureData = {
  signature?: string;
  date?: Date;
  payerIdentifier?: string;
};

export type SignatureData = {
  signers: Signers;
  signatureDetails?: SignatureDetails;
  isExternalSignature: boolean;
  eSignatureFieldType?: SignatureFieldType;
};

export type ReactElementList = Partial<Record<string, FunctionComponent>>;

export type RenderedFieldProps = {
  field: RenderingField;
  label: string;
  locale?: Language;
  subtitle?: string;
  fieldIndex?: string;
};

export const pdfTypeLookup: Record<DynamicPdfType, PdfDocumentType> = {
  [DynamicPdfType.advisor]: PdfDocumentType.advisorsReport,
  [DynamicPdfType.carrier]: PdfDocumentType.carrierReport,
  [DynamicPdfType.insured]: PdfDocumentType.application,
};
