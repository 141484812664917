import { Box } from '@breathelife/mui';
import { ReactElement } from 'react';

import { PdfDocumentTitle, PdfFieldText } from '@breathelife/ui-components';

import usePdfContext from '../../../../Context/usePdfContext';
import { translate } from '../../../../Localization/Localizer';
import { LineOfBusinessName } from '@breathelife/types';

export function PdfTitle(): ReactElement {
  const { applicantInformation, application } = usePdfContext();
  const { lang: language } = application;

  const applicantNames: string = Array.isArray(applicantInformation)
    ? applicantInformation
        .map((applicant) => `${applicant.firstName} ${applicant.lastName}`)
        .join(', ')
        .trim()
    : `${applicantInformation?.firstName || ''} ${applicantInformation?.lastName || ''}`.trim();

  const pdfTitle =
    application.lineOfBusiness === LineOfBusinessName.annuity ||
    application.lineOfBusiness === LineOfBusinessName.legacyAnnuity
      ? translate('pdfTitle.lineOfBusiness.annuity.title')
      : translate('pdfTitle.lineOfBusiness.life.title');

  return (
    <Box mb={2}>
      <PdfDocumentTitle data-testid='PdfTitle'>{pdfTitle}</PdfDocumentTitle>
      <PdfFieldText>{translate('pdfTitle.forInsuredName', { locale: language, applicantNames })}</PdfFieldText>
    </Box>
  );
}
